import { Heading, Image, Text, VStack } from "@chakra-ui/react";
import colors from "../../constants/colors/colors";

const FeatureBottomCard = ({ src, heading, text }) => {
  return (
    <VStack alignItems="start" w={{ xxs: "100%", sm: "35%" }}>
      <Image
        src={src}
        w={250}
        alt="feature-img"
        alignSelf={{ xxxs: "center", sm: "start" }}
      />
      <Heading
        fontSize={{ xxxs: 24, xxs: 28 }}
        fontWeight={600}
        textAlign={{ xxxs: "center", xxs: "left" }}
        w="100%"
      >
        {heading}
      </Heading>
      <Text
        fontSize={20}
        color={colors.lightGray}
        w={{ xxxs: "100%", sm: "80%" }}
      >
        {text}
      </Text>
    </VStack>
  );
};

export default FeatureBottomCard;
