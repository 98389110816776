import { Button } from "@chakra-ui/react";
import colors from "../../constants/colors/colors";

const PrimaryButton = ({ extraStyles, title, type }) => {
  return (
    <Button
      bg={colors.darkBlue}
      color="white"
      {...extraStyles}
      type={type || "button"}
      _hover={{ bg: colors.darkBlue }}
    >
      {title ? title : "Button"}
    </Button>
  );
};

export default PrimaryButton;
