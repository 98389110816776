import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import colors from "../../constants/colors/colors";
import HeaderBanner from "./header-banner";

const Header = () => {
  return (
    <Box mt={10} id="home">
      <Heading
        textAlign="center"
        fontSize={{ xxxs: 24, xxs: 28, xs: 34 }}
        mb={3}
        p={{ xxxs: 2, xxs: 0 }}
      >
        Manage Businesses Efficiently & Remotely
      </Heading>
      <Text
        textAlign="center"
        fontSize={{ xxxs: 18, xxs: 20, xs: 24 }}
        color={colors.lightGray}
        p={{ xxxs: 2, xxs: 0 }}
      >
        Sounds Interesting, Scroll Down to Know More
      </Text>
      <HeaderBanner />
    </Box>
  );
};

export default Header;
