import {
  Box,
  color,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";

import colors from "../../constants/colors/colors";

const FeatureCard = ({ num, heading, text, imgSrc }) => {
  return (
    <HStack
      alignItems="stretch"
      spacing={0}
      flexDir={{ xxxs: "column", xs: "row" }}
      w="90%"
    >
      <Box
        bg="white"
        rounded="xl"
        position="relative"
        p={5}
        py={10}
        order={{ xxxs: 1, sm: 0 }}
      >
        <Text
          fontSize={80}
          color={colors.featureCardNumColor}
          fontWeight={700}
          w="max-content"
          ml={20}
        >
          {num}
        </Text>
        <Heading
          position="absolute"
          top={110}
          left={{ xxxs: 8, xxs: 10 }}
          color={colors.darkGray}
          fontSize={{ xxxs: 24, xxs: 28 }}
        >
          {heading}
        </Heading>
        <Text
          fontSize={{ xxxs: 18, xxs: 22 }}
          color={colors.lightGray}
          pl={5}
          w={{ xxxs: "80%", xxs: "50%" }}
          mt={{ xxxs: 8, xxs: 4 }}
        >
          {text}
        </Text>
      </Box>
      <Flex
        bg={colors.purpleDark}
        justifyContent="center"
        alignItems="center"
        p={2}
        rounded="lg"
        order={{ xxxs: 0, sm: 1 }}
      >
        <Image
          src={imgSrc}
          w={350}
          h={imgSrc.includes("feature-3") ? 350 : 300}
        />
      </Flex>
    </HStack>
  );
};

export default FeatureCard;
