import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import headerBannerImg from "../../assets/images/v_mockup.png";
import colors from "../../constants/colors/colors";
import PrimaryButton from "../button/primary-button";

const HeaderBanner = () => {
  return (
    <HStack
      alignItems={{ xxxs: "center", xxs: "stretch" }}
      p={10}
      px={{ xxxs: 2, xxs: 8, xs: 12, sm: 20 }}
      mt={10}
      spacing={0}
      justifyContent="center"
      flexDir={{ xxxs: "column", sm: "row" }}
    >
      <VStack
        bg="white"
        rounded="xl"
        p={{ xxxs: 5, xxs: 7, xs: 10 }}
        spacing={{ xxxs: 4, xxs: 8 }}
        w={{ xxxs: "90%", xxs: "75%", sm: "60%" }}
        order={{ xxxs: 1, sm: 0 }}
      >
        <Box color={colors.darkGray} w="100%" textAlign="left">
          <Heading fontSize={{ xxxs: 20, xxs: 24, xs: 28, md: 28, lg: 34 }}>
            Business Intelligence in its
          </Heading>
          <Heading fontSize={{ xxxs: 20, xxs: 24, xs: 28, md: 28, lg: 34 }}>
            simplest form
          </Heading>
        </Box>
        <Text
          color={colors.lightGray}
          fontSize={{ xxxs: 16, xs: 18, md: 18, lg: 20 }}
          w={{ md: "100%", lg: "70%" }}
          alignSelf="start"
        >
          You name it, we have got it - employees productivity, cashflows, tax
          reminders, document management, smart attendance system and many more.
        </Text>
        <Box alignSelf="start"   w={{sm:'80%', md: "60%", lg: "30%" }} height={{sm:'80px', md: "60px", lg: "40px" }} > 
          <a href='https://play.google.com/store/apps/details?id=com.divishaio.vidhi&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <img alt='Get it on Google Play'
              src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
        </Box>
        {/* <PrimaryButton extraStyles={{ alignSelf: "start" }} /> */}
      </VStack>
      <Flex
        rounded="xl"
        flexDir="row"
        justifyContent="center"
        alignItems="center"
        p={4}
        h={{ xxxs: 340, sm: 350, md: 380 }}
        w={{ xxxs: "90%", xxs: "75%", sm: 480 }}
        bg={colors.purpleDark}
        position="relative"
        order={{ xxxs: 0, sm: 1 }}
      >
        <Image
          src={headerBannerImg}
          alt="header-img"
          h={{ xxxs: 400, xxs: 450, sm: 500, md: 530 }}
          w={{ xxs: 400, sm: 480 }}
          position="absolute"
          top={{ xs: -14, sm: -20 }}
          left={{ md: 20, lg: 0 }}
        />
      </Flex>
    </HStack>
  );
};

export default HeaderBanner;
