import Home from "./pages/home/home";
import './App.css';
import pdf from './assets/Vidhi_Terms_and_Conditions.pdf'

function App() {
  return (
    <Home pdf={pdf} />
  )

}

export default App;
