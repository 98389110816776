import React, { useEffect, useState } from 'react'
import vidhi from '../assets/logo/vidhi.png'
import {
    Box,
    Heading,
    Text,
    Flex
} from "@chakra-ui/react";
function PricingPolicy() {
    const [windowWidth, setWindowWidth] = useState();

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        });
    }, []);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, []);
    return (

        <div className='container'>
            <Box className='header' px={4} width={windowWidth > 600 ? '80%' : '100%'}>
                <Box mb={5}>
                    <Heading className='top-heading' fontSize={{ xxxs: 20, xxs: 30 }} > Vidhi Onboarding is simplified!</Heading>
                    <Heading className='top-sub-heading' mt={4} fontSize={{ xxxs: 14, xxs: 20 }} > We have plans* for all types of businesses**</Heading>
                </Box>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    width='100%'>
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        gap='40px'
                        py={4}
                        my={4}
                        flexDirection={windowWidth > 600 ? 'row' : 'column'}
                        width='100%'
                    >
                        <Box className='card'>
                            <Text className='most-popular'>
                                Most Popular
                            </Text>
                            <Box mx={windowWidth > 600 ? 20 : 8} my={8} pl={6}>

                                <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    gap='16px'
                                >
                                    <Text className='heading' fontSize={{ xxxs: 20, xxs: 26 }}>
                                        Monthly Plan
                                    </Text>
                                    <Text className='green-txt' px={2}>
                                        Pocket Friendly
                                    </Text>
                                </Flex>
                                <Text fontSize={{ xxxs: 20, xxs: 24 }} textAlign='start' mt={2}>
                                    <span className='normal-text'> ₹ 200 </span>
                                    <span className='red-txt'>
                                        <s>  ₹ 210 </s>
                                    </span>
                                </Text>
                            </Box>

                        </Box>
                        <Box className='card' >
                            <Text className='Best-value'>
                                Best Value
                            </Text>
                            <Box mx={windowWidth > 600 ? 20 : 8} my={8} pl={6}>
                                <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    gap='16px'
                                >
                                    <Text className='heading' fontSize={{ xxxs: 20, xxs: 26 }}>
                                        Yearly Plan
                                    </Text>
                                    <Text className='green-txt' px={2}>
                                        Maximum Saving
                                    </Text>
                                </Flex>
                                <Text fontSize={{ xxxs: 20, xxs: 24 }} textAlign='start' mt={2}>
                                    <span className='normal-text'> ₹ 2000 </span>
                                    <span className='red-txt'>
                                        <s>  ₹ 2520 </s>
                                    </span>
                                </Text>
                            </Box>

                        </Box>
                    </Flex>
                </Flex>



                <Box my={3} >
                    <Text fontSize={{ xxxs: 20, xxs: 24 }} className='normal-text' >
                        Every plan comes with below features and option to cancel anytime
                    </Text>
                    <Text className='des' mt={3} fontSize={windowWidth > 600 ? 20 : 16} >
                        Access to All Functionalities <br />
                        Up to 25 employee accounts <br />
                        Unlimited tasks <br />
                        Unlimited broadcasts <br />
                        And Many More
                    </Text>
                </Box>
                <Box my={3}>
                    <Text fontSize={{ xxxs: 20, xxs: 24 }} className='normal-text' mb={3}> Try Now at No Cost</Text>
                    <Text className='des' fontSize={windowWidth > 600 ? 20 : 16}> Your First Month is on us for Yearly Plan Subscribers</Text>
                    <Text className='des' fontSize={windowWidth > 600 ? 20 : 16}> Your First Week is on us for Monthly Plan Subscribers</Text>
                    <Text fontSize={{ xxxs: 20, xxs: 24 }} className='normal-text' my={2}>SUBSCRIBE NOW</Text>
                    <Text fontSize={14}> <span className='blue-txt'>(Pay Later, Start Using </span> )</Text>
                    <Text className='des' mt={3} fontSize={windowWidth > 600 ? 20 : 16} textAlign='start'>
                        * Link to access refund policy
                    </Text>
                    <Text className='des' mt={3} fontSize={windowWidth > 600 ? 20 : 16} textAlign='start'>
                    ** Also available in attractive Custom
                    Plans depending upon type and size of business
                </Text>
                </Box>
            </Box>
        </div>

    )
}

export default PricingPolicy
