import { Flex, Image, Text } from "@chakra-ui/react";

import logo from "../../assets/images/logo-employee.svg";

const Logo = ({ extraStylesText, extraStylesImg }) => {
  return (
    <Flex cursor="pointer" alignItems="center" flexDir="column">
      <Image src={logo} w={10} alt="logo" {...extraStylesImg} />
      <Text
        letterSpacing={1}
        textAlign="center"
        fontFamily="Rubik"
        fontWeight={500}
        {...extraStylesText}
      >
        VIDHI
      </Text>
    </Flex>
  );
};

export default Logo;
