import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineInstagram, AiFillLinkedin } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import colors from "../../constants/colors/colors";
import Logo from "../logo/logo";
import BsTwitter from "../../assets/logo/twitter.png";
import youtube from "../../assets/logo/youtube.png";
import fb from "../../assets/logo/fb.png";

const Footer = ({ pdf }) => {
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <Box
      px={{ xxxs: 10, xxs: 48 }}
      py={{ xxxs: 10, xxs: 20 }}
      bg={colors.siteContentBg}
    >
      {windowWidth < 600 && (
        <VStack w="100%" alignItems="flex-start" mb={5}>
          <Link href="/">Home</Link>
          <Link href="/#about">About</Link>
          {/* <Link>Pricing</Link> */}
          <Link href="/#features">Features</Link>
          <Link href="/PricingPolicy">Pricing</Link>
          <Link href="/#newsletter">Newsletter</Link>
          <Tooltip hasArrow label="+91-8951029888">
            <Link>Contact Us</Link>
          </Tooltip>
        </VStack>
      )}
      <Divider mb={4} borderBottom={`1px solid ${colors.lightGray}`} />
      <HStack justifyContent="space-between" alignItems="center">
        <Link href="/">
          <Logo extraStylesImg={{ w: 7 }} extraStylesText={{ fontSize: 15 }} />
        </Link>

        {windowWidth > 600 && (
          <HStack w="70%" justifyContent="space-between">
            <Link href="/">Home</Link>
            <Link href="/#about">About</Link>
            <Link href="/#features">Features</Link>
            <Link href="/PricingPolicy">Pricing</Link>
            <Link href="/#newsletter">Newsletter</Link>
            <Link href="/contact">Contact Us</Link>
          </HStack>
        )}
        <HStack>
          <Link href="https://www.youtube.com/channel/UCobQupYWcQk1UQL90fgqVIA" target='_blank'>
            <img src={youtube} height={25} width={25} cursor="pointer" />
          </Link>
          <Link href="https://twitter.com/vidhi_business" target='_blank'>
            <img src={BsTwitter} height={20} width={20} cursor="pointer" />
          </Link>
          <Link href="https://www.instagram.com/Vidhi_Business/" target='_blank'>
            <Icon as={AiOutlineInstagram} fontSize={25} cursor="pointer" />
          </Link>
          <Link href="https://www.linkedin.com/company/divisha-innovations/" target='_blank'>
            <Icon as={AiFillLinkedin} fontSize={25} cursor="pointer" />
          </Link>
          <Link href="https://www.facebook.com/people/VidhiApp/100089050517791/" target='_blank'>
            <img src={fb} height={25} width={25} cursor="pointer" />
          </Link>
        </HStack>
      </HStack>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap='40px'
        fontSize={12}
        mt={5}
        color={colors.lightGray}
      >

        <Link href="/TermsandConditions" style={{color: "black", fontWeight: "bold"}}>
          <a href={pdf} target="_blank"
            rel="noreferrer">
            Terms and Conditions
          </a>
        </Link>
        <Link href="/privacy_policy.html" style={{color: "black", fontWeight: "bold"}}>Privacy</Link>
        <Link href="/CancellationAndRefundPolicy" style={{color: "black", fontWeight: "bold"}}>Cancellation and refund Policy</Link>
        <Link href="/account_deletion_request.html" style={{color: "red", fontWeight: "bold"}}>Data Deletion Request</Link>

      </Flex>

      <Flex
        color={colors.lightGray}
        alignItems="center"
        justifyContent="center"
        mt={5}
        flexDir={{ xxxs: "column", xxs: "row" }}
      >

        <Flex alignItems={{ xxxs: "start", xxs: "center" }}>

          <Link href="https://www.google.co.in/maps/place/Divisha+Innovations/@12.9186381,77.6377992,15z/data=!4m5!3m4!1s0x0:0x83932ec1598e12c7!8m2!3d12.9186381!4d77.6377992">
            <Icon as={BiMap} fontSize={15} mr={1} />
          </Link>

          <Text fontSize={12} textAlign="center">
            S3, #104, 13th main, 5th Sector, HSR layout, Bangalore - 560102
            Karnataka, India |
          </Text>
        </Flex>
        <Text fontSize={12} textAlign="center" ml={1}>
          Mobile: +91-8951029888
        </Text>
      </Flex>
      <Text color={colors.lightGray} fontSize={12} textAlign="center" mt={2}>
        © 2022 Divisha Innovation Pvt. Ltd. All Rights Reserved.
      </Text>
    </Box>
  );
};

export default Footer;
