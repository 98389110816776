import { Flex, Heading, Text } from "@chakra-ui/react";
import colors from "../../constants/colors/colors";
import PrimaryButton from "../button/primary-button";

const About = () => {
  return (
    <Flex
      bg={colors.siteContentBg}
      px={10}
      py={20}
      flexDir="row"
      justifyContent="center"
      alignItems="center"
      mt={5}
    >
      <Flex flexDir="column">
        <Heading textAlign="center" w="100%" mb={5}>
          Vidhi ‘The Process’
        </Heading>
        <Text
          color={colors.lightGray}
          w={{ xxxs: "90%", xxs: "70%", sm: "40%" }}
          fontSize={20}
          mx="auto"
          textAlign="center"
          mb={5}
        >
          It leverages the Artificial Intelligence (AI) and Natural Language
          Processing (NLP) to bring-in the power of process automation to the
          unorganized business ecosystem.{" "}
        </Text>
        {/* <PrimaryButton extraStyles={{ alignSelf: "center" }} /> */}
      </Flex>
    </Flex>
  );
};

export default About;
