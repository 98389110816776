import React from 'react'
import About from "../../components/about/about";
import AboutAccordion from "../../components/about/about-accordion";
import ContactUs from "../../components/contact-us/contact-us";
import Features from "../../components/features/features";
import Header from "../../components/header/header";

function Home1() {
    return (
        <>
            <Header />
            <About />
            <Features />
            <ContactUs />
            <AboutAccordion />
        </>
    )
}

export default Home1
