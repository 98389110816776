import {
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HiOutlineMenuAlt1 } from "react-icons/hi";

import Logo from "../logo/logo";

const NavBar = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 900) setIsMobileView(true);
    else setIsMobileView(false);
  }, []);

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      py={10}
      px={{ xxxs: 10, xxs: 48 }}
      w="100%"
    >
      <Link href='/'>  <Logo /> </Link>
      {!isMobileView ? (
        <HStack alignItems="center" justifyContent="space-between" w="40%">
          <Link href="/#about">About</Link>
          <Link href="/#features">Feature</Link>
          <Link href="/PricingPolicy">Pricing</Link>
          <Link href="/#newsletter">Newsletter</Link>
          <Link href="/contact">Contact Us</Link>
        </HStack>
      ) : (
        <Menu>
          <MenuButton>
            <Icon as={HiOutlineMenuAlt1} w={8} h={8} />
          </MenuButton>
          <MenuList minW="20%">
            <MenuItem>
              <Link href="/#about">About</Link>
            </MenuItem>
            <MenuItem>
              <Link href="/#features">Feature</Link>
            </MenuItem>
            <MenuItem>
              <Link href="/PricingPolicy">Pricing</Link>
            </MenuItem>
            <MenuItem>
              <Link href="/#newsletter">Newsletter</Link>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};

export default NavBar;
