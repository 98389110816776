import React, { useState, useEffect } from 'react';
import { Box, Heading } from "@chakra-ui/react";

function AccountDeletionRequest() {
  const [windowWidth, setWindowWidth] = useState();
  const [hubspotScriptSource, setHubspotScriptSource] = useState('');
  const [isScriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Set the HubSpot form script source
    setHubspotScriptSource("https://js.hsforms.net/forms/embed/v2.js");
  }, []);

  useEffect(() => {
    // Load the HubSpot form script
    const script = document.createElement('script');
    script.src = hubspotScriptSource;
    script.async = true;
    script.id = "hubspot-script";

    script.onload = () => {
      // Create and execute the HubSpot form
      if(window.hbspt){
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '43978743',
          formId: '25d04ab5-0a12-4612-ae8f-ce39e8aed348',
          target: "#formdata"
        });

        // Set the flag to indicate that the script has loaded
        setScriptLoaded(true);
      }
    };

    // Append the script to the document body
    //document.body.appendChild(script);
    document.body.appendChild(script);

    // Cleanup: remove the script from the document body on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [hubspotScriptSource]);

  return (
    <div>
      <div className='container'>
        <Box className='header' px={4} width={windowWidth > 600 ? '80%' : '100%'}>
          <Box bg="gray.100" p={5} borderRadius="md" mb={5}>
            <Heading className='top-heading' fontSize={{ xxxs: 20, xxs: 30 }} mt={30}>
              We are sad to let you go. But we understand.
            </Heading>
            <Heading mt={4} fontSize={{ xxxs: 14, xxs: 20 }}>
              Please fill the form below, and we will send you a confirmation email once your data is deleted.
            </Heading>
          </Box>
          <Box classname="w-full">
            <div id="formdata" className="flex"></div>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default AccountDeletionRequest;
