import { Box } from "@chakra-ui/react";
import Footer from "../../components/footer/footer";
import NavBar from "../../components/navbar/navbar";
import VideoContainer from "../../components/video/video-container";
import colors from "../../constants/colors/colors";
import { Routes, Route } from "react-router-dom";
import CancellationAndRefundPolicy from '../CancellationAndRefundPolicy';
import PricingPolicy from '../PricingPolicy';
import PrivacyPolicy from '../PrivacyPolicy';
import AccountDeletionRequest from '../AccountDeletionRequest';
import Home1 from "./Home1";
import ContactUs from "../../components/contact-us/contact-us";


const Home = ({ pdf }) => {
  return (
    <Box bg={colors.siteBg} minH="100vh">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home1 pdf={pdf} />}>
        </Route>
        <Route exact path="/contact" element={<ContactUs />}>
        </Route>
        <Route exact path="/PricingPolicy" element={<PricingPolicy />}>
        </Route>
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />}>
        </Route>
        <Route exact path="/AccountDeletionRequest" element={<AccountDeletionRequest />}>
        </Route>
        <Route exact path="/CancellationAndRefundPolicy" element={<CancellationAndRefundPolicy />}>
        </Route>
      </Routes>
      <Footer pdf={pdf} />
    </Box>
  );
};

export default Home;
