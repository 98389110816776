import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import colors from "../../constants/colors/colors";
import PrimaryButton from "../button/primary-button";

const ContactUs = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 900) setIsMobileView(true);
    else setIsMobileView(false);
  }, []);

  // useEffect(() => {
  //   try {
  //     if (!document.getElementById("form")) {
  //       var f = document.createElement("iframe");
  //       f.id = "form";
  //       f.src =
  //         "https://forms.zohopublic.in/divishainnovationspvtltd/form/ContactUs/formperma/6tKYXi2sv98P0SjGCkLjGkcN9z-AjcVoqlm5NRma7bM?zf_rszfm=1";
  //       f.style.border = "none";
  //       f.style.height = "705px";
  //       f.style.width = "90%";
  //       f.style.transition = "all 0.5s ease";
  //       f.style.margin = "0 auto";
  //       var d = document.getElementById(
  //         "zf_div_6tKYXi2sv98P0SjGCkLjGkcN9z-AjcVoqlm5NRma7bM"
  //       );
  //       d.appendChild(f);
  //       window.addEventListener(
  //         "message",
  //         function () {
  //           // eslint-disable-next-line no-restricted-globals
  //           var evntData = event.data;
  //           if (evntData && evntData.constructor == String) {
  //             var zf_ifrm_data = evntData.split("|");
  //             if (zf_ifrm_data.length == 2) {
  //               var zf_perma = zf_ifrm_data[0];
  //               var zf_ifrm_ht_nw = parseInt(zf_ifrm_data[1], 10) + 15 + "px";

  //               var iframe = document
  //                 .getElementById(
  //                   "zf_div_6tKYXi2sv98P0SjGCkLjGkcN9z-AjcVoqlm5NRma7bM"
  //                 )
  //                 .getElementsByTagName("iframe")[0];

  //               if (
  //                 iframe.src.indexOf("formperma") > 0 &&
  //                 iframe.src.indexOf(zf_perma) > 0
  //               ) {
  //                 var prevIframeHeight = iframe.style.height;
  //                 if (prevIframeHeight != zf_ifrm_ht_nw) {
  //                   iframe.style.height = zf_ifrm_ht_nw;
  //                 }
  //               }
  //             }
  //           }
  //         },
  //         false
  //       );

  //       const el = document.querySelector(".backgroundSecBg");
  //       console.log(el);
  //     }
  //   } catch (e) {}
  // }, []);

  return (
    // <VStack px={10} py={20} spacing={8}>
    //   <Heading fontSize={34}>Contact Us</Heading>
    //   <form
    //     style={{ width: "80%", display: "flex", flexDirection: "column" }}
    //     onSubmit={(e) => e.preventDefault()}
    //   >
    //     <HStack mb={5} flexDir={{ xxxs: "column", xxs: "row" }}>
    //       <FormControl
    //         mr={{ xxxs: 0, xxs: 5 }}
    //         mb={{ xxxs: 5, xxs: 0 }}
    //         isRequired
    //       >
    //         <FormLabel fontSize={15} fontWeight={400}>
    //           Name
    //         </FormLabel>
    //         <Input type="text" bg="white" placeholder="Name" />
    //       </FormControl>
    //       <FormControl isRequired ml={{ xxxs: "0 !important" }}>
    //         <FormLabel fontSize={15} fontWeight={400}>
    //           Mobile Number
    //         </FormLabel>
    //         <Input type="number" bg="white" placeholder="Mobile Number" />
    //       </FormControl>
    //     </HStack>
    //     <FormControl mb={10} isRequired>
    //       <FormLabel fontSize={15} fontWeight={400}>
    //         Email
    //       </FormLabel>
    //       <Input type="email" bg="white" placeholder="Email" />
    //     </FormControl>
    //     <PrimaryButton
    //       extraStyles={{ alignSelf: "center" }}
    //       title="Submit"
    //       type="submit"
    //     />
    //   </form>
    // </VStack>
    <Box bg={colors.siteContentBg} pt={5}>
      {/* <div id="zf_div_6tKYXi2sv98P0SjGCkLjGkcN9z-AjcVoqlm5NRma7bM"></div> */}
      <Heading
        textAlign="center"
        w="100%"
        mb={5}
        fontSize={{ xxxs: 24, xxs: 28 }}
      >
        Vidhi Newsletter
      </Heading>
      <Flex
        justifyContent="space-around"
        alignItems="center"
        p={10}
        id="newsletter"
        flexDir={{ xxxs: "column", xxs: "row" }}
      >
        <Box fontSize={14}>
          <Text fontWeight="bold" fontSize={22}>
            Subscribe to our newsletter
          </Text>
          <Text>
            Subscribe to our newsletter to get our news & deals delivered to
            you.
          </Text>
          <Text>Don't worry, we hate to spam and we respect your privacy</Text>
        </Box>
        <form
          action="https://divisha.us14.list-manage.com/subscribe/post?u=91caa03fb6da21963b4523bc2&amp;id=af3780dbff&amp;f_id=00ea9ae0f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate divisha-bg"
          target="_blank"
          novalidate
          style={{ width: isMobileView ? "100%" : "20%" }}
        >
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input type="email" name="EMAIL" />
          </FormControl>
          <PrimaryButton
            title="Submit"
            type="submit"
            extraStyles={{ mt: 3, w: "100%" }}
          />
          {/* <label for="mce-EMAIL">Email</label>
          <input
            type="email"
            name="EMAIL"
            class="required email"
            id="mce-EMAIL"
          />
          <button type="submit">Submit</button> */}
        </form>
      </Flex>
    </Box>
  );
};

export default ContactUs;
