import { Box, Divider, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import colors from "../../constants/colors/colors";
import FeatureCard from "./feature-card";
import FeatureBottomCard from "./feature-bottom-card";

import featureBottomCard1 from "../../assets/images/feature-bottom-card-1.png";
import featureBottomCard2 from "../../assets/images/feature-bottom-card-2.png";
import featurePng1 from "../../assets/images/feature-1.png";
import featurePng2 from "../../assets/images/feature-2.png";
import featurePng3 from "../../assets/images/feature-3.png";

const Features = () => {
  return (
    <Box py={10} id="features">
      <Text
        textAlign="center"
        fontSize={24}
        mb={10}
        fontWeight={{ xxxs: "bold", xxs: "normal" }}
        w={{ xxxs: "80%", xxs: "100%" }}
        mx="auto"
      >
        Get ready to shift from Firefighting Mode to vidhi mode
      </Text>
      <VStack spacing={10} p={{ xxxs: 4, sm: 4 }}>
        <FeatureCard
          num="1"
          heading="Automating daily communication"
          text="Assign day-to-day tasks and broadcast announcements through ‘Speech-to-Text’ and ‘Text-to-Speech’ technology"
          imgSrc={featurePng1}
        />
        <FeatureCard
          num="2"
          heading="ART of managing tasks on the go"
          text="Assign, Re-assign & Track tasks for better efficiency in operations and employee productivity"
          imgSrc={featurePng2}
        />
        <FeatureCard
          num="3"
          heading="Managing cashflows was never so easy"
          text="Voice-enabled inputs and payables & receivables insights (daily, weekly, fortnightly & monthly) review at a click"
          imgSrc={featurePng3}
        />
      </VStack>
      <Divider
        my={10}
        w="20%"
        mx="auto"
        borderBottom={`4px solid ${colors.purpleDarker}`}
      />
      <Heading
        fontSize={{ xxxs: 24, xxs: 34 }}
        fontWeight={600}
        textAlign="center"
        w={{ xxxs: "90%", xxs: "70%", sm: "50%" }}
        mx="auto"
      >
        While you are managing businesses, we will run the errands and notify
        you about...
      </Heading>
      <HStack
        mt={10}
        p={10}
        justifyContent="space-around"
        flexDir={{ xxxs: "column", sm: "row" }}
      >
        <FeatureBottomCard
          src={featureBottomCard1}
          heading="Important date reminders"
          text="Enable reminders based on location and business type for timely tax payments, license renewals and loan repayments"
        />
        <FeatureBottomCard
          src={featureBottomCard2}
          heading="Champions of your business"
          text="Employees can compete and get rewarded for better performance based on monthly efficiency & productivity scores"
        />
      </HStack>
    </Box>
  );
};

export default Features;
